<template>
  <div>
    <!-- table -->
    <vue-good-table
      id="certificates-table"
      :columns="columns"
      :rows="rows"
      :row-style-class="rowStyleClassCancelled"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm,
      }"
      :pagination-options="{
        enabled: true,
        perPage: pageLength,
      }"
    >
      <div slot="emptystate" class="text-center">
        {{ $t("Global.Emptystate") }}
      </div>

      <template slot="table-row" slot-scope="props">
        <!-- Column: Action -->
        <span
          v-if="props.column.field === 'action' && props.row.id !== editRowId"
          class="action"
        >
          <span>
            <feather-icon
            v-if="$can($acl.action.Create, $acl.subjects.Certificates)"
              v-b-tooltip.hover.top="
                $t(
                  `Global.${
                    $can($acl.action.Update, $acl.subjects.Certificates)
                      ? 'EditSubject'
                      : 'ViewSubject'
                  }`,
                  { subject: isDocument ? $t('Management.Documents.Document') : $t('Management.Certificates.Certificate') }
                )
              "
              :icon="
                $can($acl.action.Create, $acl.subjects.Certificates)
                  ? 'Edit2Icon'
                  : 'EyeIcon'
              "
              size="16"
              class="hover-primary"
              @click="editRowId = props.row.id"
            />
          </span>
          <span
            v-if="$can($acl.action.Delete, $acl.subjects.Certificates) && !props.row.cancelled"
            v-b-tooltip.hover.bottom="
              $t('Global.CancelSubject', {
                subject: isDocument ? $t('Management.Documents.Document') : $t('Management.Certificates.Certificate'),
              })
            "
          >
            <feather-icon
              icon="XCircleIcon"
              size="16"
              class="hover-red"
              @click="cancelCertificate(props)"
            />
          </span>

          <span
            v-if="$can($acl.action.Read, $acl.subjects.Certificates) && props.formattedRow['certificateId'] !== 0"
            v-b-tooltip.hover.bottom="
              $t('Global.PrintSubject', {
                subject: isDocument ? $t('Management.Documents.Document') : $t('Management.Certificates.Certificate'),
              })
            "
          >
            <PrintDocument
            v-if="isDocument"
            :row="props.row"
            :process="process"
            ></PrintDocument>
            <PrintCertificate
            v-else
            :row="props.row"
            :process="process"
            ></PrintCertificate>
          </span>

        </span>

        <span
          v-if="props.column.field === 'action' && props.row.id === editRowId"
          class="action"
        >
        <span v-if="$can($acl.action.Update, $acl.subjects.Certificates)"
          v-b-tooltip.hover.bottom="
              $t('Global.Save')
            "
          >
            <feather-icon
              icon="SaveIcon"
              size="16"
              class="hover-green"
              @click="saveCertificate(props)"
            />
          </span>
          <span v-if="$can($acl.action.Update, $acl.subjects.Certificates) && !isDocument && processes.length > 1"
          v-b-tooltip.hover.bottom="
              $t('Management.Certificates.Collection')
            "
          >
            <feather-icon
              icon="LayersIcon"
              size="16"
              class="hover-green"
              @click="addProcesses(props)"
            />
          </span>

          <span v-if="$can($acl.action.Update, $acl.subjects.Certificates)"
          v-b-tooltip.hover.bottom="
              $t('Global.Retreat')
            "
          >
            <feather-icon
              icon="XIcon"
              size="16"
              class="hover-red"
              @click="cancelEdit"
            />
          </span>
        </span>

        <!-- Column: Common -->
        <span
          v-if="props.row.id !== editRowId && props.column.field !== 'action'"
        >
        <span v-if="props.column.field === 'certificateId' && props.formattedRow[props.column.field] === 0">
        </span>
        <span v-else-if="props.column.field === 'costs'">
          {{ secondCostFormatter.format(props.formattedRow[props.column.field]) }}
        </span>
        <span v-else-if="props.column.field === 'weight' || props.column.field === 'additive'">
          {{ weightFormatter.format(props.formattedRow[props.column.field]) }}
        </span>
        <span v-else-if="props.column.field === 'customer.name'">
          <div class="overfl">
            {{ props.formattedRow[props.column.field] }}
          </div>
        </span>
        <span v-else-if="process.processType === ProcessTypesEnum.Leerung &&  props.column.field === 'isInterim'">
            <feather-icon
              v-if="props.formattedRow[props.column.field] === false"
              icon="XCircleIcon"
              size="16"
            />
            <feather-icon
              v-if="props.formattedRow[props.column.field] === true"
              icon="CheckCircleIcon"
              size="16"
            />
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>

        </span>

        <span
          v-if="props.row.id === editRowId && props.column.field !== 'action'"
        >
          <!-- Column: date -->
          <div v-if="props.column.field === 'date'">
            <b-form-input
              v-model="props.row[props.column.field]"
              type="date"
              size="sm"
              :formatter="dateFormatter"
            />
          </div>
          <!-- Column: certificateId -->
          <b-form-input
            v-else-if="props.column.field === 'certificateId'"
            v-model="props.row[props.column.field]"
            type="number"
            size="sm"
            :disabled="true"
          />
          <!-- Column: documentId -->
          <b-form-input
            v-else-if="props.column.field === 'documentId'"
            v-model="props.row[props.column.field]"
            size="sm"
            type="text"
            :disabled="true"
          />

          <select
            v-else-if="props.column.field === 'shareholder'"
            v-model="props.row[props.column.field]"
          >
            <option
              v-for="(val, index) in Shareholder"
              :value="val"
              :key="props.column.field + ' ' + index"
            >
              {{ val }}
            </option>
          </select>

          <select
            v-else-if="props.column.field === 'customer.name'"
            v-model="props.formattedRow[props.column.field]"
            @change="customerSelected(props)"
          >
            <option
              v-for="(val, index) in customers"
              :value="val.name"
              :key="props.column.field + ' ' + index"
            >
              {{ val.name }}
            </option>
          </select>

          <CurrencyInput
            v-else-if="props.column.field === 'weight'"
            v-model="props.row[props.column.field]"
            :options="{ currency: 'EUR', currencyDisplay: 'hidden', precision: 3, locale: 'de-DE' }"
            :disabled="!$can($acl.action.Update, $acl.subjects.Processes)"
            @input="calcCosts(props.row[props.column.field], props)"
            customClass="sm"
          />

          <CurrencyInput
            v-else-if="props.column.field === 'additive'"
            v-model="props.row[props.column.field]"
            :options="{ currency: 'EUR', currencyDisplay: 'hidden', precision: 3, locale: 'de-DE' }"
            :disabled="!$can($acl.action.Update, $acl.subjects.Processes)"
            customClass="sm"
          />

          <select
            v-else-if="props.column.field === 'charge.title'"
            v-model="props.formattedRow[props.column.field]"
            @change="chargeSelected(props)"
          >
            <option
              v-for="(val, index) in charges"
              :value="val.title"
              :key="props.column.field + ' ' + index"
            >
              {{ val.title }}
            </option>
          </select>

          <b-form-textarea
            v-else-if="props.column.field === 'comment'"
            v-model="props.row[props.column.field]"
            rows="1"
            type="text"
            size="sm"
          />
          <CurrencyInput
            v-else-if="props.column.field === 'costs'"
            v-model="props.row[props.column.field]"
            :options="{ currency: 'EUR', precision: 3, locale: 'de-DE' }"
            :disabled="!$can($acl.action.Update, $acl.subjects.Processes)"
            customClass="sm"
          />

          <b-form-checkbox
          v-else-if="props.column.field === 'isInterim'"
            v-model="props.row[props.column.field]"
            :value="true"
            :unchecked-value="false"
          >
          </b-form-checkbox>

          <b-form-input
            v-else
            v-model="props.row[props.column.field]"
            type="text"
            size="sm"
          />
        </span>
        <ChargesModal
          ref="chargesModal"
          v-if="$can($acl.action.Create, $acl.subjects.Charges)"
          :can="$can($acl.action.Create, $acl.subjects.Charges)"
          @closed="modalClosed"
        />
        <ProcessModal
          ref="processModal"
          :certificate="propsRow"
          :processes="processes"
          v-if="$can($acl.action.Create, $acl.subjects.Processes)"
          :can="$can($acl.action.Create, $acl.subjects.Processes)"
          @closed="processesDone"
          @showStockWarningModal="showStockWarningModal"
        />
        <StockWarningModal
          ref="stockWarningModal"
          v-if="$can($acl.action.Create, $acl.subjects.Charges)"
          :can="$can($acl.action.Create, $acl.subjects.Charges)"
          @closed="modalClosed"
        />
        
      </template>

      <!-- pagination -->
      <template slot="pagination-bottom" slot-scope="props">
        <div class="d-flex justify-content-between flex-wrap px-2 pb-2">
          <div class="d-flex align-items-center mb-0 mt-1 flex-wrap">
            <span class="text-nowrap">
              {{ $t("Global.ShowingOneOf") }}
            </span>
            <v-select
              v-model="pageLength"
              class="mx-1"
              style="min-width: 75px"
              :clearable="false"
              :options="['10', '15', '30']"
              @input="
                (value) => props.perPageChanged({ currentPerPage: value })
              "
            />
            <span class="text-nowrap">{{ $t("Global.EntriesShown") }}</span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value) => props.pageChanged({ currentPage: value })"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import { BPagination, BFormInput, BFormTextarea, BButton, BFormCheckbox } from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import vSelect from "vue-select";
import { ProcessTypesEnum, ProcessTypes, Shareholder } from "@/data/enums";
import ChargesModal from "../../charges/charges-modal.vue";
import ProcessModal from './process-modal.vue';
import StockWarningModal from './stock-warning-modal.vue'
import PrintCertificate from "./printCertificate.vue";
import PrintDocument from "./../documents/printDocument.vue";
import { ChargeTypesEnum } from "@/data/enums";
import CurrencyInput from "@/views/miscellaneous/CurrencyInput.vue";
import { costFormatter, secondCostFormatter, weightFormatter } from '@/utility/format/formatter';


export default {
  components: {
    VueGoodTable,
    BFormInput,
    vSelect,
    BPagination,
    PrintCertificate,
    PrintDocument,
    BFormTextarea,
    CurrencyInput,
    ChargesModal,
    ProcessModal,
    StockWarningModal,
    BButton,
    BFormCheckbox
  },

  props: {
    rows: {
      type: Array,
      default: () => [],
    },
    processes: {
      type: Array,
      default: () => [],
    },
    customers: {
      type: Array,
      default: () => [],
    },
    chargesExt: {
      type: Array,
      default: () => [],
    },
    searchTerm: {
      type: String,
      default: () => "",
    },
    process: {
      type: Object,
      default: () => null,
    },
    isDocument: {
      type: Boolean,
      default: () => false,
    },
  },
  watch: {
      chargesExt: function(newValue) {
          if(newValue){
            this.charges = newValue;
        }
      },
      processes: function(newValue) {
          if(newValue){
            this.loadProcesses();
        }
      },
    },
  data() {
    return {
      costFormatter,
      weightFormatter,
      secondCostFormatter,
      rowsCopy: [],
      Shareholder,
      processesCopy: [],
      pageLength: 30,
      editRowId: "0",
      expandedIds: [],
      ChargeTypesEnum,
      propsRow: {},
      row:{},
      cancelRow: null,
      charges: [],
      ProcessTypesEnum,
      columns: [
        {
          label: this.$t("Management.Certificates.Date"),
          field: "date",
          type: "date",
          dateInputFormat: "yyyy-MM-dd",
          dateOutputFormat: "dd.MM.yyyy",
          formatFn: (value) => {
            const date = new Date(value);
            if (isNaN(date)) {
              return '';
            }
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const year = date.getFullYear();
            return `${day}.${month}.${year}`;
          },
        },
        {
          label: this.$t("Management.Certificates.Certificate"),
          field: "certificateId",
        },
        {
          label: this.process.processType === ProcessTypesEnum.VA ? this.$t("Management.Certificates.StockId"):this.$t("Management.Certificates.PackingOrderId"),
          field: this.process.processType === ProcessTypesEnum.VA ? "stockId" : "packingOrderId",
        },
        {
          label: this.$t("Management.Certificates.OrderId"),
          field: "orderId",
        },
        {
          label: this.$t("Management.Certificates.ProductId"),
          field: "productId",
        },
        {
          label: this.$t("Management.Processes.ShareholderShort"),
          field: "shareholder",
        },
        {
          label: this.$t("Management.Certificates.Weight"),
          field: "weight",
        },
        {
          label: this.$t("Management.Certificates.Charge"),
          field: "charge.title",
        },
        {
          label: this.$t("Management.Certificates.Costs"),
          field: "costs",
        },
        {
          label: this.$t("Management.Certificates.Comment"),
          field: "comment",
        },
        {
          label: this.$t("Global.Action"),
          field: "action",
          sortable: false,
        },
      ],
      documentColumns:[
      {
          label: this.$t("Management.Certificates.DocumentId"),
          field: "documentId",
        },
        {
          label: this.$t("Management.Certificates.Additive"),
          field: "additive",
        },
        {
          label: this.$t("Management.Customers.Customer"),
          field: "customer.name",
        },
      ],
    };
  },

  async created() {
    if(this.isDocument){
      this.columns = this.documentColumns.concat(this.columns);
    }else{
      if(this.process.processType === ProcessTypesEnum.Leerung){
        this.columns.splice(this.columns.length - 2, 0, {
          label: this.$t("Management.Certificates.Interim"),
          field: "isInterim",
          });
      }
    }
    
    if (this.rows) {
      this.rowsCopy = JSON.parse(JSON.stringify(this.rows));
    }
    // this.loadProcesses()
  },

  methods: {

    async loadProcesses() {
        try{
            if(this.processes.length > 0){
                this.processesCopy = JSON.parse(JSON.stringify(this.processes));
            }
        } catch (error) {
            this.$alert.error();
        }
    },
    async getChargeTable() {
      try {
        const { data } = await this.$axios.get(`charges/byChargeTypeIncludingCertificate/${this.process.processType}`);
        this.charges = data;
        const newChargeOption = [
          {
            id: "0",
            name: `${this.$tc("Global.New")} ${this.$t("Management.Charges.Charge")}`,
            title: `${this.$tc("Global.New")} ${this.$t("Management.Charges.Charge")}`,
            short: "neu",
          },
        ];
        this.charges = [].concat(newChargeOption, this.charges);
      } catch (error) {
      }
    },

    async addProcesses(props){
      if(this.isDocument){
        this.saveCertificate(props);
      } else {
        this.propsRow = props.row;
        this.row = props;
        this.$refs.processModal.modalVisible = true;
      }
    },

    async saveCertificate(props) {
      try {
        if(this.isDocument){
          props.row.customer = props.row.customer.id;
        } 
        const charge = props.row.charge;
        const processes = [];
        props.row.charge = props.row.charge?.id;
        props.row.stockId = props.row.stockId;
        props.row.certificateId = Number(props.row.certificateId);
        // ToDo: Convert to Number for GoLive
        props.row.documentId = props.row.documentId;
        props.row.productId = props.row.productId;
        props.row.weight = Number(props.row.weight);
        props.row.date = new Date(props.row.date).toISOString().split('T')[0];
        
        let generatedHint = '';
        let min = 1.00;
        if(new Date(props.row.date) < new Date("2018-08-01")){
          min = 0.75;
        }
        if(props.row.costs > 0 && props.row.costs < min){
          props.row.costs = min;
        }
        if(props.row.portions?.length > 1){
          const portions = [];
          
          for (let index = 0; index < props.row.portions.length; index++) {
            const portion = props.row.portions[index];
            if((typeof portion.processId).toString() === 'object'){
              portion.processId = portion.processId.processId;
            }
            generatedHint += `- ${portion.processId} (${weightFormatter.format(portion.portion)})\r\n`;
            
            portions.push({processId: portion.processId, portion: portion.portion});
          }
          props.row.portions = portions;
        }
        if(props.row.processes?.length > 1){
          props.row.comment = '';
          props.row.comment = props.row.comment.length > 0 ? props.row.comment + '\r\nHaupt-VA\r\n' : 'Haupt-VA\r\n';
          props.row.comment += generatedHint;
          for (let index = 0; index < props.row.processes.length; index++) {
            
            let process = props.row.processes[index];
            
            processes.push(process.id);
            
            if(index > 0){
              const processIndex = this.processesCopy.findIndex(proc => proc.id === process.id);
              const portionIndex = props.row.portions.findIndex(por => por.processId === process.processId);

              let areas = [];
              this.processesCopy[processIndex].areas.forEach(area => {
                areas.push(area.id);
              });

              this.processesCopy[processIndex].areas = areas;
              this.processesCopy[processIndex].sealDistrict = this.processesCopy[processIndex].sealDistrict?.id;
              this.processesCopy[processIndex].customer = this.processesCopy[processIndex].customer?.id;
              this.processesCopy[processIndex].gga = this.processesCopy[processIndex].gga?.id;
              if (props.row.cancelled) {
                this.processesCopy[processIndex].stock = Number(this.processesCopy[processIndex].stock) + props.row.portions[portionIndex].portion;
              } else {
                if (props.row.id === "0") {
                  this.processesCopy[processIndex].stock = Number(this.processesCopy[processIndex].stock) - props.row.portions[portionIndex].portion;
                }
              }


              const {data} = await this.$axios.patch(
                `processes/${process.id}`,
                this.processesCopy[processIndex]
              );
              this.processesCopy[processIndex] = data;
            }
          }
        } else {
          processes.push(this.process.id);
        }
        props.row.processes = processes;
        if (props.row.id === "0") {
          const { data } = await this.$axios.post("certificates", props.row);
          data.charge = charge;
          data.date = new Date(data.date).toISOString().split('T')[0];
          this.$alert.create(this.isDocument ? `${data.documentId}` : `${data.certificateId}`);
          this.$emit("add", data);

          this.rows.splice(
            this.rows.findIndex((row) => row.id === "0"),
            1
          );
        } else {
          const { data } = await this.$axios.patch(
            `certificates/${props.row.id}`,
            props.row
          );
          data.charge = charge;
          data.date = new Date(data.date).toISOString().split('T')[0];
          Object.keys(data).forEach((key) => {
            props.row[key] = data[key];
          });
          this.$alert.update(this.isDocument ? `${data.documentId}` : `${data.certificateId}`);
          this.$emit("update", data);
          this.cancelEdit();
        }
      } catch(error) {
        this.$alert.error();
      }
    },

    processesDone(){
      this.row.row = this.propsRow;
      this.saveCertificate(this.row);
    },

    cancelEdit() {
      this.editRowId = "0";
      const index = this.rows.findIndex((row) => row.id === "0");
      if (index > -1) {
        this.rows.splice(index, 1);
      };
    },

    calcCosts(val, props) {
      
      const index = this.charges.findIndex(
        (ch) => ch.from <= val && ch.to >= val
      );
      if (index > -1) {
        if (props.row.charge === null) {
          props.row.charge = this.charges[index];
          props.formattedRow.charge = this.charges[index];
        } else {
          if (props.row.charge !== this.charges[index]) {
            props.row.charge = this.charges[index];
            props.formattedRow.charge = this.charges[index];
          }
        }
      }
      props.formattedRow.weight = props.row.weight;
      if(props.row.charge.chargeType === this.ChargeTypesEnum.Urkunde){
        props.row.costs = this.charges.find(charge => charge.chargeType === this.ChargeTypesEnum.Urkunde)?.price;

      } else {
        props.row.costs = val * props.row.charge.price;
        props.formattedRow.costs = val * props.row.charge.price;
      }
      if(this.process.fillingQuantity > 0){
        if(this.process.stock - val < 0){
          this.$refs.stockWarningModal.modalVisible = true;
        }
      }
      let min = 1.00;
      if(new Date(props.row.date) < new Date("2018-08-01")){
        min = 0.75;
      }
      if(props.row.costs > 0 && props.row.costs < min){
        props.row.costs = min;
      }
      if(props.formattedRow.costs > 0 && props.row.costs < min){
        props.formattedRow.costs = min;
      }
    },

    showStockWarningModal(){
      this.$refs.stockWarningModal.modalVisible = true;
    },

    chargeSelected(props) {
      const index = this.charges.findIndex(
        (ch) => ch.title === props.formattedRow["charge.title"]
      );
      if(this.charges[index].id === "0"){
        this.$refs.chargesModal.modalVisible = true;
        return;
      }
      props.row.charge = this.charges[index];
      if(props.row.charge.chargeType === this.ChargeTypesEnum.Urkunde){
        props.row.costs = this.charges.find(charge => charge.chargeType === this.ChargeTypesEnum.Urkunde)?.price;
      } else {
        props.row.costs = props.row.weight * props.row.charge.price;
      }
      let min = 1.00;
      if(new Date(props.row.date) < new Date("2018-08-01")){
        min = 0.75;
      }
      if(props.row.costs > 0 && props.row.costs < min){
        props.row.costs = min;
      }
    },

    customerSelected(props) {
      const index = this.customers.findIndex(
        (c) => c.name === props.formattedRow["customer.name"]
      );
      props.row.customer = this.customers[index];
      props.formattedRow.customer = this.customers[index];
    },

    modalClosed(){
      if (this.$refs.chargesModal.modalVisible) {
        this.$refs.chargesModal.modalVisible = false;
      }
      this.getChargeTable();
    },

    dateFormatter(date){
      return new Date(date).toISOString().split('T')[0];
    },

    async cancelCertificate(props){
      const messageVNode = this.$createElement('div', {
        class: ['p-1'],
        domProps: {
          innerHTML: this.$t("Management.Certificates.CancelWarning"),
          style: 'font-size: 16px',
        },
      });

      const choice = await this.$bvModal.msgBoxConfirm([messageVNode], {
        title: this.$t("Management.Certificates.CancelWarningTitle"),
        size: 'sm',
        okVariant: 'primary',
        cancelVariant: 'outline-secondary',
        okTitle: this.$t("Global.OK"),
        cancelTitle: this.$t('Global.Retreat'),
        hideHeaderClose: false,
        centered: true,
        'body-class': 'p-2',
      })
      if (!choice) {
        return;
      }

      try {
        props.row.cancelled = true;
        props.row.comment += props.row.comment.length > 0 ? " - Storno" : "Storno"
        await this.saveCertificate(props);
        this.$emit('cancel', props.row);
      } catch {
        this.$alert.error()
      }
    },

    rowStyleClassCancelled(row) {
      return row.cancelled ? 'cancelled' : row.portions?.length > 1 ? 'collection' : '';
    },
  },
};
</script>
<style lang="scss">
  #certificates-table table.vgt-table {
    font-size: 12px;
  }
  .vgt-table.bordered th {
    min-width: 114px !important;
  }
  select {
    padding: 0.4rem 1rem;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #d8d6de;
    border-radius: 0.357rem;
    &:focus-visible {
      border: 1px solid #d8d6de;
    }
  }
    /* Works for Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Works for Firefox */
    input[type="number"] {
      appearance: textfield;
      -moz-appearance: textfield;
    }
    tr.cancelled {
      background: rgb(245, 245, 245);
      td{
        color: #cacaca;
      }
    }
    tr.collection {
      background: rgb(250, 250, 250);
      td{
        color: #b7b5b5;
      }
    }
    .custom-checkbox{
      margin-top: 0;
    }
    .overfl {
      max-width: 150px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
</style>
