<template>
  <b-card class="p-2" no-body>
    <b-tabs card>
      <b-tab no-body :title="`${$t('Management.Certificates.Certificates')} (${certificateRows.length})`">
        <div
          class="custom-search d-flex justify-content-between align-items-center mb-2 px-2"
        >
          <h2 class="m-0 font-weight-bolder">
            {{ $t("Global.All") }}
            {{ $t("Management.Certificates.Certificates") }}
          </h2>
          <div class="d-flex">
            <div class="d-flex align-items-center">
              <b-form-input
                v-model="certificateSearchTerm"
                :placeholder="$t('Global.Search')"
                type="text"
                class="d-inline-block search"
              />
            </div>
            <b-button
            v-if="$can($acl.action.Create, $acl.subjects.Certificates)"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              class="ml-2 d-flex align-items-center"
              variant="primary"
              @click="addNewCertificateRow"
            >
              <feather-icon class="mr-1" icon="FileTextIcon" size="21" />
              {{ $tc('Global.New', 1) }} {{ $t('Management.Certificates.Certificate') }}
            </b-button>
            <b-button
            v-if="$can($acl.action.Create, $acl.subjects.Certificates)"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              class="ml-2 d-flex align-items-center"
              variant="primary"
              @click="$bvModal.show('empty-certificate-modal')"
            >
              <feather-icon class="mr-1" icon="FileTextIcon" size="21" />
              {{ $t('Management.Certificates.EmptyCertificate') }}
            </b-button>
          </div>
        </div>
        <b-modal
          id="empty-certificate-modal"
          centered
          no-close-on-backdrop
          no-close-on-esc
          no-enforce-focus
          class="modal-dialog modal-dialog-centered"
        >
          <template #modal-title>
            {{ $t('Management.Certificates.EmptyCertificate') }}
          </template>
          <template>
            <b-row>
              <b-col lg="12" md="12" sm="12">
                <label>
                  {{ $t("Management.Certificates.Date") }}
                </label>
                <b-form-input
                  v-model="emptyCertificate.date"
                  type="date"
                  size="sm"
                  :formatter="dateFormatter"
                />
              </b-col>

              <b-col lg="12" md="12" sm="12">
                <label>
                  {{ $t("Management.Certificates.Weight") }}
                </label>
                <CurrencyInput
                  v-model="emptyCertificate.weight"
                  :options="{ currency: 'EUR', currencyDisplay: 'hidden', precision: 3, locale: 'de-DE' }"
                  customClass="sm"
                />
              </b-col>
              <b-col lg="12" md="12" sm="12">
                <label>
                  {{ $t("Management.Certificates.CertificateType") }}
                </label>
                <v-select
                  id="certificateTypes"
                  v-model="emptyCertificate.certificateType"
                  :disabled="!$can($acl.action.Update, $acl.subjects.Processes)"
                  :clearable="false"
                  :placeholder="
                    $t('Global.Select', {
                      subject: $t('Management.Certificates.CertificateType'),
                    })
                  "
                  :options="CertificateTypes"
                  @input="changeComment"
                >
                <div slot="no-options">{{ $t("Global.NothingFound") }}</div>
                  <template #option="{ label }">
                    <div>
                      {{ $t(`Management.Certificates.CertificateTypes.${label}`) }}
                    </div>
                  </template>
                  <template #selected-option="{ label }">
                    <div>
                      {{ $t(`Management.Certificates.CertificateTypes.${label}`) }}
                    </div>
                  </template>
                </v-select>
              </b-col>
              <b-col lg="12" md="12" sm="12">
                <label>
                  {{ $t("Management.Certificates.Comment") }}
                </label>
                <b-form-textarea
                  v-model="emptyCertificate.comment"
                  rows="4"
                  type="text"
                  size="sm"
                />
              </b-col>
            </b-row>
          </template>
          <template #modal-footer>
            <div class="buttons-container d-flex">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                @click="$bvModal.hide('empty-certificate-modal');addEmptyCertificateRow()"
              >
                {{ $t("Global.Accept") }}
              </b-button>

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="outline-secondary"
                @click="
                  $bvModal.hide('empty-certificate-modal');
                  resetModalValues();
                "
                >
                {{ $t("Global.Retreat") }}
              </b-button>
            </div>
          </template>
        </b-modal>
        <Table 
        :rows="certificateRows" 
        :process="process" 
        :search-term="certificateSearchTerm"
        :isDocument="false"
        :processes="processes"
        :chargesExt="charges"
        :customers="customers"
        @cancel="cancelCertificateRow"
        @add="addCertificateEntry"
        @update="updateEmitter"
        />
      </b-tab>
      <b-tab no-body :title="`${$t('Management.Documents.Documents')} (${documentRows.length})`">
        <div
          class="custom-search d-flex justify-content-between align-items-center mb-2 px-2"
        >
          <h2 class="m-0 font-weight-bolder">
            {{ $t("Global.All") }}
            {{ $t("Management.Documents.Documents") }}
          </h2>
          <div class="d-flex">
            <div class="d-flex align-items-center">
              <b-form-input
                v-model="documentSearchTerm"
                :placeholder="$t('Global.Search')"
                type="text"
                class="d-inline-block search"
              />
            </div>
            <b-button
            v-if="$can($acl.action.Create, $acl.subjects.Certificates)"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              class="ml-2 d-flex align-items-center"
              variant="primary"
              @click="addEmptyDocumentRow"
            >
              <feather-icon class="mr-1" icon="FileTextIcon" size="21" />
              {{ $tc('Global.New', 2) }} {{ $t('Management.Documents.Document') }}
            </b-button>

          </div>
        </div>
        <Table 
          :rows="documentRows" 
          :process="process" 
          :search-term="documentSearchTerm"
          :isDocument="true"
          :processes="processes"
          :chargesExt="charges"
          :customers="customers"
          @add="addDocumentEntry"
          @update="updateEmitter"
        />
      </b-tab>
    </b-tabs>
    <div :class="isLoading ? 'backdrop-certs' : ''">
          <div :class="isLoading ? 'loading-certs' : ''">
            <div class="effect-1 effects"></div>
            <div class="effect-2 effects"></div>
            <div class="effect-3 effects"></div>
          </div>
        </div>
  </b-card>
</template>

<script>
import { BCard, BTabs, BTab, BFormInput, BButton, BModal, BFormGroup, BFormDatepicker, BRow, BCol, BFormTextarea } from "bootstrap-vue";
import Table from "./certificates/table.vue";
import { ProcessTypesEnum } from "@/data/enums";
import deleteEntry from "@/utility/scripts/delete";
import CurrencyInput from "@/views/miscellaneous/CurrencyInput.vue";
import { weightFormatter } from '@/utility/format/formatter';
import { ChargeTypesEnum, CertificateTypes, CertificateTypesEnum } from "@/data/enums";
import vSelect from "vue-select";

export default {
  components: {
    BCard,
    BTabs,
    BTab,
    BFormInput,
    BButton,
    Table,
    BModal,
    BFormGroup,
    BFormDatepicker,
    BRow,
    BCol,
    CurrencyInput,
    BFormTextarea,
    vSelect,
  },

  props: {
    process: {
      type: Object,
      default: () => null,
    },
  },

  watch: {
    process: function(newValue) {
        if(newValue){
            this.load();
        }
      },
    },

  data: () => ({
    certificateSearchTerm: "",
    documentSearchTerm: "",
    certificateRows: [],
    documentRows: [],
    ProcessTypesEnum,
    ChargeTypesEnum,
    emptyCharge: null,
    weightFormatter,
    CertificateTypes,
    CertificateTypesEnum,
    isLoading: false,
    processes: [],
    charges:[],
    customers:[],
    emptyCertificate: {
      weight: 0,
      date: new Date().toISOString().split('T')[0],
      comment: "Interimsbestand",
    }
  }),

  async created() {
    this.load();
  },

  methods: {
    async load() {
      this.getCertificates();
      this.getEmptyCharge();
      this.loadProcesses();
      this.getChargeTable();
      this.getCustomers();
    },
    async loadProcesses() {
        try{
            if(this.processes.length === 0){
                const obj = {
                  sortType: this.process.sortType,
                  sort: this.process.sorts[0].name,
                  harvestYear: this.process.harvestYear.toString(),
                }
                const { data } = await this.$axios.post(`processes/forCollection`, obj);
                this.processes = data;
                this.processesCopy = data;JSON.parse(JSON.stringify(this.processes));
            }
        } catch (error) {
            this.$alert.error();
        }
    },
    async getCustomers() {
      try {
        const { data } = await this.$axios.get(`customers`);
        this.customers = data;
      } catch (error) {
        this.$alert.error();
        this.$router.push({ name: "customers" });
      }
    },
    async getChargeTable() {
      try {
        const { data } = await this.$axios.get(`charges/byChargeTypeIncludingCertificate/${this.process.processType}`);
        this.charges = data;

        const newChargeOption = [
          {
            id: "0",
            name: `${this.$tc("Global.New")} ${this.$t("Management.Charges.Charge")}`,
            title: `${this.$tc("Global.New")} ${this.$t("Management.Charges.Charge")}`,
            short: "neu",
          },
        ];
        this.charges = [].concat(newChargeOption, this.charges);
      } catch (error) {
      }
    },
    async getCertificates() {
      try {
        this.isLoading = true;
        const { data } = await this.$axios.get(
          "certificates/certificatesbyprocess/" + this.process.id
        );

        this.documentRows = data.filter(dat => this.containsKey(dat, 'documentId'));

        if(this.documentRows.length > 0){
          this.documentRows.forEach(row => {
            if(row.charge){
              row.charge.label = row.charge.from + "kg - " + row.charge.to + "kg (" + row.charge.price + ")";
            } else {
              row.charge = {
                label: "Nicht mehr vorhanden"
              }
            }
            row.date = this.formatDate(row.date);
          });
        }

        
        this.certificateRows = data.filter(dat => !this.containsKey(dat, 'documentId'));
        if(this.certificateRows.length > 0){
          this.certificateRows.forEach(row => {
            if(row.charge){
            row.charge.label = row.charge.from + "kg - " + row.charge.to + "kg (" + row.charge.price + ")";
            } else {
              row.charge = {
                label: "Nicht mehr vorhanden"
              }
              }
            row.date = this.formatDate(row.date);
          });
        }
        this.isLoading = false;
      } catch (error) {
          this.isLoading = false;
          this.$alert.error();
      }
    },

    formatDate(dateFromDB) {
      const date = new Date(dateFromDB);
      if (isNaN(date)) {
        return ''; // Rückgabe eines leeren Strings für ungültige Daten
      }
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();

      return `${month}/${day}/${year}`;
    },

    async getEmptyCharge(){
      const { data } = await this.$axios.get(`charges/byChargeType/${this.ChargeTypesEnum.Leer}`);
      this.emptyCharge = data;
    },
    
    containsKey(obj, key ) {
      return Object.keys(obj).includes(key);
    },

    resetModalValues(){
      this.emptyCertificate = {
        weight: 0,
        date: new Date().toISOString().split('T')[0],
        comment: "Interimsbestand"
      }
    },
    async addNewCertificateRow() {
      const certificateId = await this.getLastCertificateId();
      this.certificateRows.push({
        id: "0",
        date: new Date().toISOString().split('T')[0],
        certificateId:  `${certificateId + 1}`,
        stockId: null,
        productId: null,
        weight: null,
        charge: null,
        shareholder: this.process.shareholder,
        comment: "",
        hint: null,
        orderId: null,
        packingOrderId: null,
        processes: [this.process.id],
        portions: [{processId: this.process.processId, portion: 0}],
        cancelled: false,
        certificateType: CertificateTypesEnum.Standard,
        isInterim: false,
      });
    },

    async addEmptyCertificateRow() {
      const newCert = {
        id: "0",
        isEmpty: true,
        date: this.emptyCertificate.date,
        certificateId: 0,
        stockId: null,
        productId: null,
        weight: this.emptyCertificate.weight,
        charge: this.emptyCharge[0].id,
        shareholder: this.process.shareholder,
        comment: this.emptyCertificate.comment,
        hint: null,
        orderId: null,
        packingOrderId: null,
        processes: [this.process.id],
        portions: [{processId: this.process.processId, portion: 0}],
        cancelled: false,
        certificateType: this.emptyCertificate.certificateType,
        isInterim: false,
      };
      const { data } = await this.$axios.post("certificates", newCert);
      data.date = new Date(data.date).toISOString().split('T')[0];
      this.$alert.create(this.$t("Management.Certificates.EmptyCertificate"));
      this.addCertificateEntry(data);
      this.resetModalValues();
    },

   async addEmptyDocumentRow() {
      const documentNr = await this.getLastDocumentId();
      const certificateId = await this.getLastCertificateId();
      this.documentRows.push({
        id: "0",
        date: new Date().toISOString().split('T')[0],
        certificateId:  `${certificateId + 1}`,
        stockId: null,
        productId: null,
        weight: null,
        charge: null,
        shareholder: this.process.shareholder,
        comment: "",
        hint: null,
        orderId: null,
        packingOrderId: null,
        processes: [this.process.id],
        documentId: `${documentNr + 1}`,
        additive: null,
        customer: null,
        cancelled: false,
        certificateType: CertificateTypesEnum.Standard,
        isInterim: false,
      });
    },

    async getLastCertificateId() {
      const { data } = await this.$axios.get('certificates/certificate/nextCertificateId');
      return data;
    },

    async getLastDocumentId() {
      const { data } = await this.$axios.get('certificates/certificate/nextDocumentId');
      return data;
    },

    updateEmitter(entry){
      this.$emit("addWeight", entry.weight);
    },

    addCertificateEntry(entry) {
      this.certificateRows.push(entry);
      this.$emit("addWeight", entry.weight);
    },

    async cancelCertificateRow(row) {
      const weight = row.weight;
      this.getCertificates();
      this.$emit("deleteWeight", weight);
    },

    addDocumentEntry(entry) {
      this.documentRows.push(entry);
      this.$emit("addWeight", entry.weight);
    },

    async deleteDocumentRow(row) {
      try {
          await deleteEntry(this, {
            id: row.id,
            article: `${this.$t("Global.Articles_3")}`,
            individual: `"${row.certificateId}"`,
            subject: `${this.$t("Management.Documents.Document")}`,
            endpoint: "certificates/",
          });
          this.getCertificates();
        } catch (error) {
          // continue regardless of error
        }
    },

    dateFormatter(date){
      return new Date(date).toISOString().split('T')[0];
    },
    changeComment(type){
      this.emptyCertificate.comment = this.$t(`Management.Certificates.CertificateTypes.${type}`)
    }
  },
};
</script>
<style>
.backdrop-certs {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Hintergrundfarbe und Transparenz anpassen */
}

.loading-certs {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 3px solid transparent;
}

.loading-certs .effect-1,
.loading-certs .effect-2,
.loading-certs .effect-3 {
  position: absolute;
  width: 100%;
  height: 100%;
  border: 3px solid transparent;
  border-left: 3px solid #61673E;
  border-radius: 50%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.loading-certs .effect-1 {
  animation: rotate 1s ease infinite;
}

.loading-certs .effect-2 {
  animation: rotateOpacity 1s ease infinite 0.1s;
}

.loading-certs .effect-3 {
  -webkit-animation: rotateOpacity 1s ease infinite 0.2s;
  animation: rotateOpacity 1s ease infinite 0.2s;
}

.loading-certs .effects {
  transition: all 0.3s ease;
}
</style>