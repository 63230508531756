<template>
    <feather-icon
      icon="PrinterIcon"
      size="16"
      class="hover-green"
      @click="print"
    />
</template>
<script>
import { jsPDF } from "jspdf";
import { ProcessTypesEnum, SortTypesEnum } from '@/data/enums';
import autoTable from 'jspdf-autotable'
import { costFormatter, percentFormatter, secondCostFormatter, weightFormatter } from '@/utility/format/formatter';

export default {
    components:{},
    props:{
        row: {
            type: Object,
            required: true,
        },
        process: {
            type: Object,
            required: true,
        },
    },
    data: () => ({
        ProcessTypesEnum,
        SortTypesEnum,
        costFormatter,
        percentFormatter,
        secondCostFormatter,
        weightFormatter
    }),

    methods: {
        async print(row){

            const translationsRoot = "Management.Certificates.PrintDocument";
            const doc = new jsPDF();
            
            const pageHeight = doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
            const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
            const center = pageWidth / 2;

            let german = true;
            let thirdCountry = false;
            this.process.areas.forEach(area => {
                if(area.country.short !== "DE") german = false;
            });
            this.process.areas.forEach(area => {
                if(area.country.isThirdCountry){
                    thirdCountry = true;
                    german = false;
                }
            });
            // Draw boxes:
            // doc.setFontSize(17);
            doc.setFillColor(0,0,0);                                                            // Borders
            
            doc.rect(137.6, 16.6, 17.8, 7.8, 'F');                                              // DocumentId box
            doc.rect(center / 2 - 0.4, 42.6, center + 0.8, 7.8, 'F');                           // Description box
            doc.rect(22, 60.6, pageWidth - 44, 7.8, 'F');                                       // Bezugsnummer box
            doc.rect(22, 74.6, (center / 2) * 1.5 + 0.8, 7.8, 'F');                             // Number box
            doc.rect(pageWidth - 75, 74.6, center / 2 + 0.8, 7.8, 'F');                         // date box
            doc.rect(22, 89.6, pageWidth - 44, 7.8, 'F');                                       // customer box
            doc.rect(22, 98.6, pageWidth - 44, 7.8, 'F');                                       // customer box
            doc.rect(22, 112.6, pageWidth - 44, 7.8, 'F');                                      // Betrieb box
            doc.rect(22, 121.6, pageWidth - 44, 7.8, 'F');                                      // Betrieb box
            doc.rect(center - (center / 4) - 10.4, 136.6, center / 2 + 20, 7.8, 'F');           // Weight box
            doc.rect(center - (center / 4) - 10.4, 145.6, center / 2 + 20, 7.8, 'F');           // Weight box
            doc.rect(center - (center / 4) - 10.4, 187.6, center / 2 + 20, 7.8, 'F');           // Haervestyear box
            doc.rect(pageWidth - 95, pageHeight - 60.6, center / 2 + 20.8, 7.8, 'F');           // Train date box
            doc.rect(43, pageHeight - 23.6, 30, 7.8, 'F');                                      // NR DE box


            doc.setFillColor(231,230,230);                                                      // Fill-color

            doc.rect(138, 17, 17, 7, 'F');                                                      // DocumentId box
            doc.rect(center / 2, 43, center, 7, 'F');                                           // Description box
            doc.rect(22.4, 61, pageWidth - 44.8, 7, 'F');                                       // Bezugsnummer box
            doc.rect(22.4, 75, ((center / 2) * 1.5), 7, 'F');                                   // Number box
            doc.rect(pageWidth - 74.6, 75, (center / 2), 7, 'F');                               // date box
            doc.rect(22.4, 90, pageWidth - 44.8, 7, 'F');                                       // customer box
            doc.rect(22.4, 99, pageWidth - 44.8, 7, 'F');                                       // customer box
            doc.rect(22.4, 113, pageWidth - 44.8, 7, 'F');                                      // Betrieb box
            doc.rect(22.4, 122, pageWidth - 44.8, 7, 'F');                                      // Betrieb box
            doc.rect(center  - (center / 4) -10, 137, center / 2 + 20 - 0.8, 7, 'F');           // Weight box
            doc.rect(center  - (center / 4) -10, 146, center / 2 + 20 - 0.8, 7, 'F');           // Weight box
            doc.rect(center  - (center / 4) -10, 188, center / 2 + 20 - 0.8, 7, 'F');           // Haervestyear box
            doc.rect(pageWidth - 94.6, pageHeight - 60.2, center / 2 + 20, 7, 'F');             // Train date box
            doc.rect(43.4, pageHeight - 23.2, 29.2, 7, 'F');                                      // NR DE box

            // Certificate Header
            doc.setFont("Times")

            doc.setFontSize(22);
            doc.text(this.$t(`${translationsRoot}.Header`), center, 23,"center");

            doc.setFontSize(24);
            if(german){
                doc.text(`${this.$tc(`${translationsRoot}.Title`, 1)} ${this.$tc(`${translationsRoot}.Title`, 2)}`, center, 36,"center");
            } else if(thirdCountry) {
                doc.setFontSize(20);
                doc.text(`${this.$tc(`${translationsRoot}.ThirdCountry`, 1)} ${this.$tc(`${translationsRoot}.ThirdCountry`, 2)}`, center, 36,"center");
            } else {
                doc.text(`${this.$tc(`${translationsRoot}.Title`, 2)}`, center, 36,"center");
            }


            doc.setFont("Helvetica")

            doc.setFontSize(7);
            doc.text(this.$tc(`${translationsRoot}.Seal`, 1), 40, pageHeight - 43);
            doc.text(this.$tc(`${translationsRoot}.Seal`, 2), 40, pageHeight - 40);

            doc.setFontSize(8);
            doc.text(this.$t(`${translationsRoot}.Subheader`), center, 28, "center");

            doc.setFontSize(11);
            doc.text(this.$t(`${translationsRoot}.Description`), center, 41, "center");
            doc.text(this.$tc(`${translationsRoot}.TEXT`, 1), center, 54.5, "center");
            doc.text(this.$tc(`${translationsRoot}.TEXT`, 2), center, 59, "center");
            doc.text(this.$t(`${translationsRoot}.Number`), 22, 73);
            doc.text(this.$t(`${translationsRoot}.Date`), pageWidth - 22, 73, "right");
            doc.text(this.$t(`${translationsRoot}.CustomerHead`), center, 88.5, "center");
            doc.text(this.$t(`${translationsRoot}.CompanyHead`), center, 111, "center");
            doc.text(this.$t(`${translationsRoot}.Areas`), center, 158, "center");
            doc.text(this.$t(`${translationsRoot}.HarvestYear`), center, 186, "center");
            doc.text(this.$t(`${translationsRoot}.Weight`), center, 135, "center");
            doc.text(this.$t(`${translationsRoot}.Of`), 50, 151);
            doc.text(this.$t(`${translationsRoot}.Additive`), 145, 151);
            doc.text(this.$t(`${translationsRoot}.NR`), 18, pageHeight - 16);
            doc.text(this.$tc(`${translationsRoot}.Signed`, 1), 137.5, pageHeight - 25.5);
            doc.text(this.$tc(`${translationsRoot}.Signed`, 2), 130, pageHeight - 21);

            doc.line(120, pageHeight - 30, 175, pageHeight - 30);

            doc.text(`${this.row.documentId}`, 142, 22);
            let lupulin = ""
            if(this.process.processType === this.ProcessTypesEnum.VA){
                if(this.process.sortType === this.SortTypesEnum.Lupulinpellets){
                    lupulin = this.$t(`${translationsRoot}.Types.Lupulin`) + " ";
                }
            }
            doc.text(lupulin + this.$t(`${translationsRoot}.Types.${this.process.processType === this.ProcessTypesEnum.VA ? "VA": "Leerung"}`), 
            center, 48, "center");
            const date = new Date(this.row.date).toLocaleDateString("fr-CH")
            doc.text(this.process.processId, center, 66, "center");
            doc.text(date, 150, 80);

            if(this.process.processType === this.ProcessTypesEnum.VA){
                doc.text(` ${this.row.stockId} / ${this.row.orderId} / ${this.row.productId}`, 30, 80);
            } else {
                doc.text(`${this.row.packingOrderId} / ${this.row.orderId} / ${this.row.productId}`, 30, 80);
            }


            doc.text(`${this.row.customer?.name} ${this.row.customer?.street}`, center, 95, "center");
            doc.text(`${this.row.customer?.zip} ${this.row.customer?.city} ${this.row.customer.country?.name}`, center, 104, "center");

            doc.text(this.$tc(`${translationsRoot}.HopfenveredlungAdress`, 1), center, 118, "center");
            doc.text(this.$tc(`${translationsRoot}.HopfenveredlungAdress`, 2), center, 127, "center");

            doc.text(`${this.weightFormatter.format(this.row.weight)}`, center, 142, "center");
            doc.text(`${this.weightFormatter.format(this.row.additive)}`, center, 151, "center");

            doc.text(this.process.harvestYearCollection ? this.process.harvestYearCollection : this.process.harvestYear, center, 193, "center");

            doc.text(`Train, ${new Date(this.row.createdAt).toLocaleDateString("fr-CH")}`, 150, 242, "center");

            let shortYear = '';
            if (this.process.harvestYear && this.process.harvestYear.length === 4 && !this.process.harvestYearCollection) {
                shortYear = this.process.harvestYear.charAt(2) + this.process.harvestYear.charAt(3);
            }
            if (shortYear.length > 0) {
                doc.text(`${shortYear} ${this.row.certificateId}`, 50, pageHeight - 18);
            } else {
                doc.text(`${this.row.certificateId}`, 52, pageHeight - 18);
            }
            

            // Areas
            let areas = [];
            const areaCols = Math.ceil(this.process.areas.length / 3);
            let areaCellWidthFactor = 4;
            let areaCell = pageWidth / areaCellWidthFactor;

            for (let index = 1; index < areaCols; index++) {
                areaCellWidthFactor+=2
                cell = pageWidth / areaCellWidthFactor;
            }
            if(areaCols === 1){
                areaCellWidthFactor = 3;
                areaCell = pageWidth / areaCellWidthFactor;
            }

            for (let index = 0; index < this.process.areas.length; index++) {
                if(index + 2 < this.process.areas.length && areaCols > 2){
                    areas.push(
                        [
                            `${this.process.areas[index].country.short} - ${this.process.areas[index].name}`,
                            `${this.process.areas[index+1].country.short} - ${this.process.areas[index+1].name}`,
                            `${this.process.areas[index+2].country.short} - ${this.process.areas[index+2].name}`,
                        ]);
                    index+=2;
                } else if(index + 1 < this.process.areas.length && areaCols > 1){
                    areas.push(
                        [
                        `${this.process.areas[index].country.short} - ${this.process.areas[index].name}`,
                            `${this.process.areas[index+1].country.short} - ${this.process.areas[index+1].name}`,
                        ]);
                    index++;
                }
                else{
                    areas.push([`${this.process.areas[index].country.short} - ${this.process.areas[index].name}`]);
                }
            }

            const areaColumnStyle = { fontSize: 11, halign: "center", fillColor: (231,230,230), lineWidth: 0.3, lineColor: (0,0,0), cellWidth: areaCell};
            autoTable(doc, {
                startY: 160,
                body: areas,
                head: header,
                theme:"plain",
                margin: {left: areaCellWidthFactor === 8 ? areaCell/2 : areaCell, right: areaCellWidthFactor === 8 ? areaCell/2 : areaCell},
                styles: {
                    cellPadding: {top: 1.2, bottom: 1.2}
                },
                columnStyles: { 0: areaColumnStyle, 
                                1: areaColumnStyle,
                                2: areaColumnStyle,
                                3: areaColumnStyle,
                                4: areaColumnStyle,
                                5: areaColumnStyle},
                didParseCell: data => {
                    if (data.cell && data.cell.section === "head") {
                        data.cell.styles.halign = "center";
                        data.cell.styles.fontStyle = "normal";
                    }   
                }
            });

            // Sorts
            let sorts = [];
            const sortCols = this.process.sorts.length >= 9 ? 3 : Math.ceil(this.process.sorts.length / 3);
            const header = [[this.$t(`${translationsRoot}.Sorts`), this.$t(`${translationsRoot}.SortPercentage`)]];
            let cellWidthFactor = 4;
            let cell = pageWidth / cellWidthFactor;

            for (let index = 1; index < sortCols; index++) {
                cellWidthFactor+=2;
                cell = pageWidth / cellWidthFactor;
                header[0].push(this.$t(`${translationsRoot}.Sorts`));
                header[0].push(this.$t(`${translationsRoot}.SortPercentage`));
            }

            for (let index = 0; index < this.process.sorts.length; index++) {
                if(index + 2 < this.process.sorts.length && sortCols > 2){
                    sorts.push(
                        [
                            this.process.sorts[index].name, `${this.percentFormatter.format(this.process.sorts[index].sortPercentage)} % v.H.`,
                            this.process.sorts[index+1].name, `${this.percentFormatter.format(this.process.sorts[index+1].sortPercentage)} % v.H.`,
                            this.process.sorts[index+2].name, `${this.percentFormatter.format(this.process.sorts[index+2].sortPercentage)} % v.H.`
                        ]);
                    index+=2;
                } else if(index + 1 < this.process.sorts.length && sortCols > 1){
                    sorts.push(
                        [
                            this.process.sorts[index].name, `${this.percentFormatter.format(this.process.sorts[index].sortPercentage)} % v.H.`,
                            this.process.sorts[index+1].name, `${this.percentFormatter.format(this.process.sorts[index+1].sortPercentage)} % v.H.`
                        ]);
                    index++;
                }
                else{
                    sorts.push([this.process.sorts[index].name, `${this.percentFormatter.format(this.process.sorts[index].sortPercentage)} % v.H.`]);
                }
            }
            if(sortCols === 2){
               cell = 42; 
            }

            const columnStyle = { fontSize: sortCols < 3 ? 11 : 10, halign: "center", fillColor: (231,230,230), lineWidth: 0.3, lineColor: (0,0,0), cellWidth: cell};
            autoTable(doc, {
                startY: 198,
                body: sorts,
                head: header,
                theme:"plain",
                margin: {left: sortCols === 2 ? 21 : cell, right: sortCols === 2 ? 21 : cell},
                styles: {
                    cellPadding: {top: 1.2, bottom: 1.2}
                },
                columnStyles: { 0: columnStyle, 
                                1: columnStyle,
                                2: columnStyle,
                                3: columnStyle,
                                4: columnStyle,
                                5: columnStyle},
                didParseCell: data => {
                    if (data.cell && data.cell.section === "head") {
                        data.cell.styles.halign = "center";
                        data.cell.styles.fontStyle = "normal";
                        data.cell.styles.fontSize = sortCols < 3 ? 10 : 7;
                    }   
                }
            });
         
            doc.setLineWidth(0.4)
            // top-border
            doc.line(12.83, 12, pageWidth - 12.83, 12);
            // left-border
            doc.line(13, 12, 13, pageHeight - 13);
            // right-border
            doc.line(pageWidth - 13, 12, pageWidth - 13, pageHeight - 13);
            // bottom-border
            doc.line(12.83, pageHeight - 13, pageWidth - 12.83, pageHeight - 13);

            // top-border
            doc.line(14.83, 14, pageWidth - 14.83, 14);
            // left-border
            doc.line(15, 14, 15, pageHeight - 15);
            // right-border
            doc.line(pageWidth - 15, 14, pageWidth - 15, pageHeight - 15);
            // bottom-border
            doc.line(14.83, pageHeight - 15, pageWidth - 14.83, pageHeight - 15);

            doc.setLineWidth(0.8)
            // top-border
            doc.line(13.7, 13, pageWidth - 13.7, 13);
            // left-border
            doc.line(14, 13, 14, pageHeight - 14);
            // right-border
            doc.line(pageWidth - 14, 13, pageWidth - 14, pageHeight - 14);
            // bottom-border
            doc.line(13.7, pageHeight - 14, pageWidth - 13.7, pageHeight - 14);

            doc.addPage();
            if(this.process.showText){
                doc.setFontSize(16);
                doc.text(this.$tc(`${translationsRoot}.Back_Text`, 1), center-3, 260, "center");
                doc.text(this.$tc(`${translationsRoot}.Back_Text`, 2), center-3, 268, "center");
            }

            doc.setFontSize(9);
            doc.text(this.$t(`${translationsRoot}.Charge`) + `   ${this.secondCostFormatter.format(this.row.costs)}`, center-3, 278, "center");
            
            window.open(doc.output('bloburl'), '_blank');
        },

        getProcessIds() {
            let ids = "";
            this.row.processes.forEach(process => {
                ids += process.processId
            })
            return ids;
        }
    }
}
</script>