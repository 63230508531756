<template>
  <section>
    <b-card v-if="process" class="p-2">
      <Form
        ref="form"
        :process="process"
        :processType="process.processType"
        :can="$can($acl.action.Create, $acl.subjects.Processes)"
        @submit="submit"
        @changeProcess="changeProcess"
      ></Form>
    </b-card>
    <Index v-if="process && process.id"
    :process="process"
    @addWeight="addWeight"
    @deleteWeight="deleteWeight"
    ></Index>
  </section>
</template>

<script>

import Form from './components/form.vue';
import { BCard } from 'bootstrap-vue';
import Index from '../certs-and-docs/index.vue';
import { ProcessTypesEnum, CertificateTypesEnum, ProcessTypes } from '@/data/enums';

export default {
  components: {
    Form,
    BCard,
    Index,
  },


  data: () => ({
    process: null,
    searchTerm: "",
    ProcessTypesEnum,
    CertificateTypesEnum,
  }),

  async created() {
    this.getProcess();
  },
  methods: {
    async changeProcess(processId) {
      try {
        const { data } = await this.$axios.get(
          `processes/${processId}`
        );
        this.process = data;
        this.process?.areas?.map(function (x) {
        return (x.label =
          x.name + " - " + x.short + " (" + x.country.short + ")");
        });

        this.process?.sorts?.map(function (x) {
          return (x.label =
            x.name + " - " + x.short + " (" + x.group + ")");
        });
      } catch (error) {
        this.$alert.error();
        this.$router.push({ name: "seals/processes" });
      }
    },
    async getProcess() {
      try {
        const { data } = await this.$axios.get(
          `processes/${this.$route.params.id}`
        );
        this.process = data;
        this.process?.areas?.map(function (x) {
        return (x.label =
          x.name + " - " + x.short + " (" + x.country.short + ")");
        });

        this.process?.sorts?.map(function (x) {
          return (x.label =
            x.name + " - " + x.short + " (" + x.group + ")");
        });
      } catch (error) {
        this.$alert.error();
        this.$router.push({ name: "seals/processes" });
      }
    },

    async addWeight(){
      if(this.process.calculateStock)
      this.submit(false);
    },

    async getCertificates(){
      const { data } = await this.$axios.get(
          "certificates/certificatesbyprocess/" + this.process.id
        );

        return data;
    },

    async deleteWeight(){
      this.submit(false);
    },

    async submit(alert = true) {
      try {
        if(this.process.calculateStock){
          const certs = await this.getCertificates();
          let certWeight = 0;
          if(this.process.processType === ProcessTypesEnum.VA){

            certs.forEach((cert) => {
              if(!cert.cancelled){
                const portionIndex = cert.portions.findIndex(por => por.processId === this.process.processId);
                if(cert.portions.length > 1 && portionIndex > -1){
                  certWeight += Number(cert.portions[portionIndex].portion);
                } else {
                  if(cert.certificateId){
                    certWeight += Number(cert.weight);
                  } else {
                    if(cert.certificateType === CertificateTypesEnum.SonstigesP || cert.certificateType === CertificateTypesEnum.Interim){
                      certWeight -= Number(cert.weight);
                    } else {
                      certWeight += Number(cert.weight);
                    }
                  }
                }
              }
          });
          this.process.stock = Number(this.process.fillingQuantity) - certWeight;
          } else {
            certs.forEach((cert) => {
              
              if(!cert.cancelled){
                if(cert.certificateType === CertificateTypesEnum.Interim){
                  certWeight += Number(cert.weight);
                } 
                if(cert.isInterim){
                  const portionIndex = cert.portions.findIndex(por => por.processId === this.process.processId);
                  if(cert.portions.length > 1 && portionIndex > -1){
                    certWeight -= Number(cert.portions[portionIndex].portion);
                  } else {
                    certWeight -= Number(cert.weight);
                  }
                }
              }
          });
          this.process.stock = certWeight;
          }
          
        } else {
          this.process.stock = Number(this.process.stock);
        }

        const updateData = JSON.parse(JSON.stringify(this.process));
        let areas = [];
        updateData.areas.forEach(area => {
          areas.push(area.id)
        })
        updateData.areas = areas;

        updateData.sealDistrict = updateData.sealDistrict ? updateData.sealDistrict.id : "";
        updateData.customer = updateData.customer?.id;
        updateData.gga = updateData.gga?.id;
        if(this.process.processType === ProcessTypesEnum.VA){
          updateData.fillingQuantity = Number(updateData.fillingQuantity);
        } else{
          updateData.fillingQuantity = 0;
        }
        updateData.stock =  Number(updateData.stock);
        updateData.unit = updateData.unit ? updateData.unit: "0";
        updateData.rawHop = updateData.rawHop ? Number(updateData.rawHop): 0;
        const { data } = await this.$axios.patch(
          `processes/${this.process.id}`,
          updateData,
        )
        if(alert) this.$alert.update(data.processId)

      } catch(ex) {
        this.$alert.error()
      }
    },
  },
};
</script>

