<template>
  <b-modal
    id="stock-warning-modal"
    ref="modal"
    centered
    no-close-on-backdrop
    no-close-on-esc
    no-enforce-focus
    class="modal-dialog modal-dialog-centered"
  >
  <template #modal-title>
        {{ $t("Global.Warning") }}
        </template>
        <template>
        {{ $t("Management.Processes.StockWarning") }}
        </template>
    <template #modal-footer>
      <div class="buttons-container d-flex">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          @click="closeModal"
        >
          {{ $t("Global.Close") }}
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { BButton, BFormInput, BFormGroup } from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { preventUnsavedChangesPopupLevel } from "@/utility/mixins/preventUnsavedChanges";
import { required } from "@validations";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BButton,
    BFormInput,
    BFormGroup
  },

  mixins: [preventUnsavedChangesPopupLevel],

  props: {
    can: {
      type: Boolean,
      required: true,
    },
  },

  data: () => ({
    required,
  }),

  methods: {
    closeModal(){
      this.modalVisible = false;
    },
    async reset() {
      this.$emit("closed");
    },
  },
};
</script>
<style lang="scss">
.buttons-container {
  gap: 1rem;
}
#charges-modal{
  .modal-dialog {
    max-width:850px;
  }
  .card {
    box-shadow: none;
    padding: 0!important;
  }
}
</style>
