<template>
    <b-modal
      v-if="certificate && processes.length > 0"
      id="select-process-modal"
      ref="modal"
      centered
      no-close-on-backdrop
      no-close-on-esc
      no-enforce-focus
      class="modal-dialog modal-dialog-centered"
    >
      <template #modal-title>
        {{ $t('Global.Select', { subject: $t('Management.Processes.Processes'), }) }}
      </template>



      <b-row v-for="(portion, index) in certificate.portions" :key="index">
        <b-col lg="5" md="5" sm="8">
          <b-form-group :label="index === 0 ? $t('Management.Certificates.Process'):$t('Management.Processes.Process')" label-for="process">

              <v-select
                id="processId"
                v-model="portion.processId"
                :placeholder="
                  $t('Global.Select', {
                    subject: $t('Management.Processes.Process'),
                  })
                "
                :options="paginated" :filterable="false" @search="onSearch"
                :label="'processId'"
                :key="'id'"
                :disabled="!$can($acl.action.Update, $acl.subjects.Processes)"
                @option:selected="processSelected"
              >
              <div slot="no-options">{{ $t("Global.NothingFound") }}</div>
                <template #option="{ processId }">
                  <div class="d-flex">
                    {{ processId }}
                  </div>
                </template>
                <template #selected-option="{ processId }">
                  <div>{{ processId }}</div>
                </template>
                <li slot="list-footer" class="pagination">
                  <button :disabled="!hasPrevPage" @click="offset -= limit" class="pagButton">Vorherige</button>
                  <button :disabled="!hasNextPage" @click="offset += limit" class="pagButton">Nächste</button>
                </li>
              </v-select>
          </b-form-group>
        </b-col>
        <b-col lg="5" md="5" sm="8">
          <b-form-group
            :label="$t('Management.Processes.Portion')"
            label-for="portion"
            >
              <div class="text-center">
                <CurrencyInput
                  id="portion"
                  v-model="portion.portion"
                  :options="{ currency: 'EUR', currencyDisplay: 'hidden', precision: 3, locale: 'de-DE' }"
                  :disabled="!$can($acl.action.Update, $acl.subjects.Processes)"
                  @input="setCounter(portion)"
                />
              </div>
          </b-form-group>
        </b-col>
        <b-col
          lg="1"
          md="1"
          sm="2"
          class="text-center align-self-center d-flex"
        >
        <div v-if="certificate.processes.length > 1">
            <feather-icon
              icon="TrashIcon"
              size="25"
              class="hover-red"
              @click="removeProcess(process)"
            />
          </div>
          <div v-if="index === certificate.processes.length - 1">
            <feather-icon
              icon="PlusCircleIcon"
              size="25"
              class="hover-primary"
              @click="addProcess"
            />
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col           
        lg="12"
          md="12"
          sm="12">
          <div class="portion-error">
            {{ portionError }}
          </div>
          
        </b-col>
      </b-row>

      <template #modal-footer>
        <div class="buttons-container d-flex">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            :diabled="portionError.length > 0"
            @click="closeModal"
          >
            {{ $t("Global.Save") }}
          </b-button>
        </div>
      </template>
    </b-modal>
  </template>
  
  <script>
  import { BButton, BFormInput, BFormGroup, BRow, BCol, BModal } from "bootstrap-vue";
  import { ValidationProvider, ValidationObserver } from "vee-validate";
  import { preventUnsavedChangesPopupLevel } from "@/utility/mixins/preventUnsavedChanges";
  import { required } from "@validations";
  import vSelect from "vue-select";
  import CurrencyInput from "@/views/miscellaneous/CurrencyInput.vue";

  export default {
    components: {
      ValidationProvider,
      ValidationObserver,
      BButton,
      BFormInput,
      BFormGroup,
      BRow,
      BCol,
      vSelect,
      BModal,
      CurrencyInput
    },
  
    mixins: [preventUnsavedChangesPopupLevel],
  
    props: {
      can: {
        type: Boolean,
        required: true,
      },
      certificate: {
        type: Object,
        required: true
      },
      processes: {
        type: Array,
        required: true
      }
    },
  
    data: () => ({
      required,
      selectedProcesses: [],
      count: 0,
      portionError: "",
      search: '',
      offset: 0,
      limit: 10,
    }),

    watch: {
        certificate: function(newValue) {
            if((typeof this.certificate.processes[0]).toString() === "string" && this.certificate.processes.length === 1){
                const process = this.processes.find(process => process.id === this.certificate.processes[0]);
                if(process){
                  this.certificate.processes.splice(0, 1, process);
                  this.selectedProcesses.push(process);
                  this.certificate.portions[0] = {process: process?.id, portion: 0};
                }

            }
        }
    },

    computed: {
    filtered() {
      return this.processes.filter((process) =>
        process?.processId.toLowerCase().includes(this.search.toLowerCase())
      )
    },
    paginated() {
      return this.filtered.slice(this.offset, this.limit + this.offset)
    },
    hasNextPage() {
      const nextOffset = this.offset + this.limit
      return Boolean(
        this.filtered.slice(nextOffset, this.limit + nextOffset).length
      )
    },
    hasPrevPage() {
      const prevOffset = this.offset - this.limit
      return Boolean(
        this.filtered.slice(prevOffset, this.limit + prevOffset).length
      )
    },
  },

    methods: {
      onSearch(query) {
      this.search = query
      this.offset = 0
    },
        removeProcess(processToRemove){
            this.certificate.processes.splice(
                this.certificate.processes.findIndex((process) => process.id === processToRemove.id),
                1
            );
            this.selectedProcesses.splice(
                this.selectedProcesses.findIndex((process) => process.id === processToRemove.id),
                1
            );

            if (this.certificate.processes.length === 0) {
                this.certificate.processes.push({
                    id: 0,
                    processId: "",
                });
            }
        },
        processSelected(process){
            this.selectedProcesses.push(process);

            this.certificate.processes.splice(
                this.certificate.processes.findIndex((process) => process.id === 0),
                1
            );
            this.certificate.processes.push(process);
        },

        setCounter(val){
          const process = this.processes.find(process => process.processId === val.processId.processId);

          if(process && process.fillingQuantity && process.stock - val.portion < 0){
            this.$emit("showStockWarningModal");
          }
          this.count = 0;
          this.certificate.portions.forEach(portion => {
            this.count += portion.portion;
          });
          if(this.count > Number(this.certificate.weight) || this.count < Number(this.certificate.weight)){
            this.portionError = `Die Summe der Anteile (wenn angegeben) muss insgesamt ${this.certificate.weight}kg betragen.`;
          } else {
            this.portionError = '';
          }
        },

        addProcess() {
            this.certificate.processes.push({
                id: 0,
                processId: "",
            });
            this.certificate.portions.push({
                id: 0,
                processId: "",
                portion: 0
            });
        },

        closeModal(){
            this.modalVisible = false;
        },

        async reset() {
            this.$emit("closed");
        },
    },
  };
  </script>
  <style lang="scss">
  .buttons-container {
    gap: 1rem;
  }
  #select-process-modal{
    .modal-dialog {
      max-width:850px;
      height:800px;
    }
    .card {
      box-shadow: none;
      padding: 0!important;
    }
  }
  .pagination {
  display: flex;
  margin: 0.25rem 0.25rem 0;
}
.pagButton {
  flex-grow: 1;
  border-color: #425E5B !important;
  background-color: #425E5B !important;
  text-align: center;
  padding: 0.786rem 1rem;
  border-radius: 0.358rem;
  color: white;
}
.pagButton:hover {
  cursor: pointer;
}
.portion-error{
color: red;
}
  </style>
  